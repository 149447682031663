import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import moment, { duration } from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider, Field, FieldArray } from "formik";
import {
  DatePickerCustom,
  ImageView,
  SelectCustomLable,
  SelectMultipleCustom,
  Texteditor,
  UploadImage,
} from ".";

import { Input, Select as SelectAnt } from "antd";
import { apiURL } from "../../utils/config";
import { createCourses, updateCourses } from "../../appRedux/actions/courses";
import ReactStars from "react-stars";
import { PlaceHolder } from "../../Routes/ImagePath";
import { currencyConverter, currencyConverterForm } from "../../utils/currency";
import MetaTagForm from "./MetaTagForm";
import MetaTag from "./MetaTag";

const { Option } = SelectAnt;
const INITIAL_STATE = {
  title: "",
  headlines: "",
  description: "",
  out_come: "",
  category: "",
  affiliation: {
    _id: "",
    name: "",
    imageUrl: "",
  },
  courseType: "",
  duration: "",
  duration_per_week: "",
  merchant: "",
  websiteUrl: "",
  level: "",
  mode: "",
  price: "",
  rating: 4.5,
  review_count: 20,
  price_detail: {
    price: 0,
    symbol: "₹",
    currency: "INR",
    certificate: true,
  },
  image_url: "",
  meta: [
    { name: "title", property: "", content: "", placeholder: "Page title here" },
    { name: "description", property: "", content: "", placeholder: "Page description here" },
    // { name: "keywords", property: "", content: "", placeholder: "Page keywords here" },
  ]
  // imageUrl:
  //   "https://prod-discovery.edx-cdn.org/cdn-cgi/image/width=auto,height=auto,quality=75,format=webp/media/course/image/a2139fb0-ef8f-4c5c-8ff3-fcddc7f3c827-22730e17f471.small.jpg",
};
function FormView(props) {
  const { courseInfo, courseFilters, currencyList, queryInfo } = useSelector((state) => ({
    courseInfo: state.courses.courseInfo,
    courseFilters: state.courses.courseFilters,
    queryInfo: state.courses.queryInfo,
    currencyList: state.common.currencyList,
  }));

  const currencyData = useSelector((state) => state.common.currencyData);
  const selectedCurrency = useSelector((state) => state.common.selectedCurrency);
  const [oneYears, setOneYears] = useState(true);
  const [categoriesList, setCategoriesList] = useState(courseFilters.category);
  const [modeList, setModeList] = useState(courseFilters.mode);
  const [platformList, setPlatformList] = useState(courseFilters.platform);
  const [affiliationList, setAffiliationList] = useState(courseFilters.affiliation);
  const [courseTypeList, setCourseTypeList] = useState(courseFilters.courseType);
  const [languageList, setLanguageList] = useState(courseFilters.language);
  const [ratingList, setRatingList] = useState(courseFilters.rating);
  const [levelList, setLevelList] = useState(courseFilters.level);

  useEffect(() => {
    setCategoriesList(courseFilters.category);
    setModeList(courseFilters.mode);
    setPlatformList(courseFilters.platform);
    setAffiliationList(courseFilters.affiliation);
    setCourseTypeList(courseFilters.courseType);
    setLanguageList(courseFilters.language);
    setRatingList(courseFilters.rating);
    setLevelList(courseFilters.level);
  }, [courseFilters]);
  // const handleDateChange1 = (date) => {
  //   setSelectedDate1(date);
  // };
  const { isUpdate, reJoin } = props;
  const [formInfo, setFormInfo] = useState(INITIAL_STATE);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(hideMessage());
    if (isUpdate && courseInfo && courseInfo._id) {
      const affiliationInfo = affiliationList.find(
        (info) => info._id === courseInfo.affiliation._id
      );
      const meta = courseInfo.meta
        ? courseInfo.meta.map(tag => ({
          name: tag.name || "",
          property: tag.property || "",
          content: tag.content || "",
        }))
        : INITIAL_STATE.meta;
      console.log({ affiliationInfo });
      setFormInfo({
        ...INITIAL_STATE,
        ...courseInfo,
        courseType: courseInfo.course_type && courseInfo.course_type._id,
        websiteUrl: courseInfo.website_url,
        category: courseInfo.category && courseInfo.category._id,
        // course_type: courseInfo.category && courseInfo.category._id,
        affiliation: {
          ...courseInfo.affiliation,
          imageUrl:
            courseInfo.affiliation &&
              courseInfo.affiliation.imageUrl &&
              courseInfo.affiliation.imageUrl !== null
              ? courseInfo.affiliation.imageUrl
              : affiliationInfo && affiliationInfo.imageUrl
                ? affiliationInfo.imageUrl
                : "",
        },
        price_detail: {
          ...courseInfo.price_detail,
          price:
            courseInfo.price_detail && courseInfo.price_detail.price > 0
              ? courseInfo.price_detail.price
              : courseInfo.price,
        }, meta,
      });
    } else {
      //console.log("");
      setFormInfo({
        ...INITIAL_STATE,
      });
    }
  }, [isUpdate, courseInfo]);

  const FormSchema = Yup.object().shape({
    title: Yup.string().required("Course title is required."),
    headlines: Yup.string().required("Headlines is required."),
    description: Yup.string().required("About us is required."),
    out_come: Yup.string().required("What you Will learn is required."),
    merchant: Yup.string().required("Platform is required."),
    category: Yup.string().required("Category is required."),
    duration: Yup.string().required("Duration is required."),
    duration_per_week: Yup.string(),
    // duration_per_week: Yup.string().required("Duration per week is required."),
    language: Yup.string().required("Language is required."),
    courseType: Yup.string().required("Course type is required."),
    level: Yup.string().required("Course level is required."),
    mode: Yup.string().required("Course mode is required."),
    affiliation: Yup.object().shape({
      name: Yup.string().required("Course affiliation is required."),
    }),
    websiteUrl: Yup.string()
      .required("Web site url is required.")
      .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i, "Please enter a valid URL."),
    meta: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().nullable(),
        property: Yup.string().nullable(),
        itemProp: Yup.string().nullable(),
        content: Yup.string().required(" is required"),
      })
    ),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInfo,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data });

      const info = {
        symbol: "₹",
        currency: "INR",
      };

      let priceDetail = data.price_detail;
      let price = priceDetail?.price || 0;
      if (priceDetail.currency !== "INR") {
        price = currencyConverterForm(price, info, currencyData);
        priceDetail = { ...priceDetail, price, symbol: "₹", currency: "INR" };
      }
      const meta = data.meta.map(({ name, property, content }) => {
        // Remove empty `name` or `property` keys and `placeholder`
        const cleanedItem = { content };
        if (name) cleanedItem.name = name;
        if (property) cleanedItem.property = property;
        return cleanedItem;
      }).filter(item => item.name || item.property)
      if (isUpdate && courseInfo && courseInfo._id) {
        dispatch(
          updateCourses(
            {
              ...data,
              courseId: courseInfo._id,
              course_type: data.courseType,
              website_url: data.websiteUrl,
              // price: data.price_detail.price,
              price: price,
              price_detail: priceDetail,
              meta,
              _id: undefined,
            },
            queryInfo
          )
        ).then(() => {
          navigate("/course/list");
        });
      } else {
        dispatch(
          createCourses({
            ...data,
            course_type: data.courseType,
            website_url: data.websiteUrl,
            price: price,
            price_detail: priceDetail,
            meta
          })
        ).then(() => {
          navigate("/course/list");
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  console.log({ errors, touched, values });

  return (
    <FormikProvider value={formik}>
      <Form
        id="contactForm"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className="signin-form"
      >
        {/* <div>
          <label className="col-form-label">
            <b>Course Details:</b>
          </label>
        </div> */}
        <div className="row m-0">
          <div className="col-md-8">
            <div className="row m-0">
              <div className="col-md-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Title <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" {...getFieldProps("title")} />
                  <p className="error-msg text-danger">{touched.title && errors.title}</p>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group">
                  <label className="col-form-label">
                    Headlines<span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    rows={3}
                    type="text"
                    {...getFieldProps("headlines")}
                  />
                  <p className="error-msg text-danger">{touched.headlines && errors.headlines}</p>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="col-form-label">
                    About Us<span className="text-danger">*</span>
                  </label>
                  <Texteditor
                    description={values.description}
                    onChange={(value) => setFieldValue("description", value)}
                    height="350px"
                  />
                  <p className="error-msg text-danger">
                    {touched.description && errors.description}
                  </p>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="col-form-label">
                    What You Will Learn <span className="text-danger">*</span>
                  </label>
                  <Texteditor
                    description={values.out_come}
                    onChange={(value) => setFieldValue("out_come", value)}
                    height="350px"
                  />
                  <p className="error-msg text-danger">{touched.out_come && errors.out_come}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="row m-0">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label className="col-form-label"> Cover Image</label>
                  <div>
                    <UploadImage
                      {...{
                        fileList:
                          values.image_url && values.image_url !== ""
                            ? [
                              {
                                uid: "-1",
                                name: "image.png",
                                status: "done",
                                url: values.image_url,
                              },
                            ]
                            : [],
                        setFileList: (list) => {
                          var newList = [];
                          for (let i = 0; i < list.length; i++) {
                            let listInfo = list[i];
                            console.log({ listInfo }, "------------------------");
                            if (
                              listInfo.status === "done" &&
                              listInfo.response &&
                              listInfo.response.status === 200
                            ) {
                              newList.push({
                                name: listInfo.name,
                                status: listInfo.status,
                                size: listInfo.size,
                                type: listInfo.type,
                                uid: listInfo.uid,
                                url: apiURL + listInfo.response.data,
                              });
                            } else {
                              newList.push(listInfo);
                            }
                          }
                          console.log({ newList }, "------------------------");
                          // setFileList(newList);
                          setFieldValue("image_url", newList.length ? newList[0]["url"] : "");
                        },
                        image: values.image_url !== "" ? values.image_url : null,
                        setImageUrl: (url) => {
                          setFieldValue("image_url", url);
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="input-block  ">
                  <label className="col-form-label">
                    Platform<span className="text-danger">*</span>
                  </label>
                  <SelectCustomLable
                    className="pb-3 text-capitalize"
                    placeholder="Select Platform"
                    showSearch={true}
                    options={platformList.map((option) => ({
                      _id: option.name,
                      label: option.name,
                    }))}
                    value={values.merchant}
                    onChange={(value) => {
                      if (value !== "select") {
                        setFieldValue("merchant", value);
                      }
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "merchant" } });
                    }}
                  />
                  <p className="error-msg text-danger">{touched.merchant && errors.merchant}</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-block  ">
                  <label className="col-form-label">
                    Category<span className="text-danger">*</span>
                  </label>
                  <SelectCustomLable
                    className="pb-3 text-capitalize"
                    placeholder="Select Category"
                    showSearch={true}
                    options={categoriesList.map((option) => ({
                      _id: option._id,
                      name: option.name,
                    }))}
                    value={values.category}
                    onChange={(value) => {
                      if (value !== "select") {
                        setFieldValue("category", value);
                      }
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "category" } });
                    }}
                  />
                  <p className="error-msg text-danger">{touched.category && errors.category}</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group  ">
                  <label className="col-form-label">
                    Total Duration <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" {...getFieldProps("duration")} />
                  <p className="error-msg text-danger">{touched.duration && errors.duration}</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group  ">
                  <label className="col-form-label">
                    {" "}
                    Duration Per Week
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...getFieldProps("duration_per_week")}
                  />
                  <p className="error-msg text-danger">
                    {touched.duration_per_week && errors.duration_per_week}
                  </p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="input-block  ">
                  <label className="col-form-label">
                    Language<span className="text-danger">*</span>
                  </label>
                  <SelectCustomLable
                    className="pb-3 text-capitalize"
                    placeholder="Select Language"
                    showSearch={true}
                    options={languageList
                      .filter((info) => info.name && !info.name.includes("?"))
                      .map((option) => ({
                        _id: option.name,
                        label: option.name,
                      }))}
                    value={values.language}
                    onChange={(value) => {
                      if (value !== "select") {
                        setFieldValue("language", value);
                      }
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "language" } });
                    }}
                  />
                  <p className="error-msg text-danger">{touched.language && errors.language}</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-block  ">
                  <label className="col-form-label">
                    Course Type<span className="text-danger">*</span>
                  </label>
                  <SelectCustomLable
                    className="pb-3 text-capitalize"
                    placeholder="Select Course Type"
                    showSearch={true}
                    options={courseTypeList.map((option) => ({
                      _id: option._id,
                      name: option.name,
                    }))}
                    value={values.courseType}
                    onChange={(value) => {
                      if (value !== "select") {
                        setFieldValue("courseType", value);
                      }
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "courseType" } });
                    }}
                  />
                  <p className="error-msg text-danger">{touched.courseType && errors.courseType}</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-block  ">
                  <label className="col-form-label">
                    Course Level<span className="text-danger">*</span>
                  </label>
                  <SelectCustomLable
                    className="pb-3 text-capitalize"
                    placeholder="Select Course Level"
                    showSearch={true}
                    options={levelList
                      .filter((info) => info.name && info.name !== "")
                      .map((option) => ({
                        _id: option.name,
                        name: option.name,
                      }))}
                    value={values.level}
                    onChange={(value) => {
                      if (value !== "select") {
                        setFieldValue("level", value);
                      }
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "level" } });
                    }}
                  />
                  <p className="error-msg text-danger">{touched.level && errors.level}</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-block  ">
                  <label className="col-form-label">
                    Mode<span className="text-danger">*</span>
                  </label>
                  <SelectCustomLable
                    className="pb-3 text-capitalize"
                    placeholder="Select Course Level"
                    showSearch={true}
                    options={modeList.map((option) => ({
                      _id: option,
                      label: option,
                    }))}
                    value={values.mode}
                    onChange={(value) => {
                      if (value !== "select") {
                        setFieldValue("mode", value);
                      }
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "mode" } });
                    }}
                  />
                  <p className="error-msg text-danger">{touched.mode && errors.mode}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div className="col-md-8">
            <div className="row m-0">
              <div className="col-md-8">
                <div className="input-block  ">
                  <label className="col-form-label">
                    Affiliation<span className="text-danger">*</span>
                  </label>
                  <SelectCustomLable
                    className="pb-3 text-capitalize"
                    placeholder="Select Affiliation"
                    showSearch={true}
                    options={affiliationList}
                    value={values.affiliation ? values.affiliation.name : ""}
                    onChange={(value) => {
                      if (value !== "select") {
                        const info = affiliationList.find((info) => info._id === value);
                        setFieldValue("affiliation._id", value);
                        setFieldValue("affiliation.name", info.name);
                        setFieldValue("affiliation.imageUrl", info.imageUrl);
                      }
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "affiliation._id" } });
                    }}
                  />
                  <p className="error-msg text-danger">
                    {touched.affiliation &&
                      touched.affiliation._id &&
                      errors.affiliation &&
                      errors.affiliation._id}
                  </p>
                </div>
                <div className="row m-0">
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Rating <span className="text-danger">*</span>
                      </label>{" "}
                      <ReactStars
                        count={5}
                        onChange={(value) => setFieldValue("rating", value)}
                        value={
                          values.rating && values.rating !== ""
                            ? parseFloat(`${values.rating}`.replace(" stars", ""))
                            : 0
                        }
                        size={34}
                        edit={true}
                        color2={"#ffd700"}
                      />
                      <p className="error-msg text-danger">{touched.rating && errors.rating}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Review Count</label>
                      <input
                        className="form-control"
                        type="number"
                        {...getFieldProps("review_count")}
                      />
                      <p className="error-msg text-danger">
                        {touched.review_count && errors.review_count}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    WebUrl <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" {...getFieldProps("websiteUrl")} />
                  <p className="error-msg text-danger">{touched.websiteUrl && errors.websiteUrl}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label className="col-form-label"> Affiliation Image</label>
                  <div>
                    <ImageView
                      {...{
                        fileList:
                          values.affiliation && values.affiliation.imageUrl !== ""
                            ? [
                              {
                                uid: "-1",
                                name: "image.png",
                                status: "done",
                                url: values.affiliation.imageUrl,
                              },
                            ]
                            : [
                              {
                                uid: "-1",
                                name: "image.png",
                                status: "done",
                                url: PlaceHolder,
                              },
                            ],
                        setFileList: (list) => {
                          var newList = [];
                          for (let i = 0; i < list.length; i++) {
                            let listInfo = list[i];
                            if (
                              listInfo.status === "done" &&
                              listInfo.response &&
                              listInfo.response.status === 200
                            ) {
                              newList.push({
                                name: listInfo.name,
                                status: listInfo.status,
                                size: listInfo.size,
                                type: listInfo.type,
                                uid: listInfo.uid,
                                url: apiURL + listInfo.response.data,
                              });
                            } else {
                              newList.push(listInfo);
                            }
                          }
                          // setFieldValue(
                          //   "affiliationImage",
                          //   newList.length ? newList[0]["url"] : ""
                          // );
                        },
                        image: values.affiliation && values.affiliation.imageUrl,
                        setImageUrl: (url) => {
                          setFieldValue("affiliation.imageUrl", url);
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="col-md-4">
            <div className="row m-0">
              <div className="col-md-12 mt-2 p-2 ">
                <div
                  className="row m-0  p-2 "
                  style={{ background: "#eeeeee", borderRadius: "5px" }}
                >
                  <div>
                    <label className="col-form-label">
                      <b>Billing Information:</b>
                    </label>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="col-form-label"> Price</label>
                      <input
                        className="form-control"
                        type="number"
                        {...getFieldProps("price_detail.price")}
                      />
                      <p className="error-msg text-danger">
                        {touched.price_detail &&
                          touched.price_detail.price &&
                          errors.price_detail &&
                          errors.price_detail.price}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="col-form-label"> Currency</label>
                      <SelectCustomLable
                        className="pb-3 text-capitalize"
                        placeholder="Select Category"
                        showSearch={true}
                        options={currencyList.map((option) => ({
                          _id: option.currency,
                          label: option.currency,
                        }))}
                        value={values.price_detail.currency}
                        onChange={(value) => {
                          // symbol
                          console.log({ value });
                          if (value !== "select") {
                            const info = currencyList.find((curr) => curr.currency === value);
                            let priceValue = values?.price_detail?.price || 0;
                            const price = currencyConverterForm(priceValue, info, currencyData);
                            setFieldValue("price_detail.price", price);
                            setFieldValue("price_detail.currency", value);
                            setFieldValue("price_detail.symbol", info.symbol);
                          }
                        }}
                        onBlur={() => {
                          handleBlur({ target: { name: "price_detail.currency" } });
                        }}
                      />
                      {/* <input
                        className="form-control"
                        type="text"
                        {...getFieldProps("price_detail.currency")}
                      /> */}
                      <p className="error-msg text-danger">
                        {touched.price_detail &&
                          touched.price_detail.currency &&
                          errors.price_detail &&
                          errors.price_detail.currency}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        {/* {!(isUpdate && courseInfo && courseInfo._id) && ( */}
        <MetaTag {...{ values, errors, touched, setFieldValue }}
        />
        {/* // )} */}

        <div className="submit-section mb-5">
          <button type="submit" className="btn btn-primary submit-btn">
            {isUpdate && courseInfo && courseInfo._id ? "Update" : "Submit"}
          </button>
        </div>
      </Form>
    </FormikProvider>

  );
}

export default FormView;
